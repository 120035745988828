<script setup lang="ts">
import type { SharedOffcanvas } from '~/components/shared/SharedOffcanvas.vue';
import { computed } from 'vue';

const props = defineProps<{
    offcanvas: SharedOffcanvas;
    id: string;
}>();

const offcanvasContent = computed(() => props.offcanvas?.offcanvasContent?.value);
const offcanvasProps = computed(() => props.offcanvas?.offcanvasProps?.value);
const offcanvasId = computed(() => props.offcanvas?.offcanvasId?.value);
</script>

<template>
    <ClientOnly>
        <Teleport
            to="#offcanvas-content"
            :disabled="id !== offcanvasId"
        >
            <component
                :is="offcanvasContent"
                v-if="offcanvasContent && id === offcanvasId"
                v-bind="offcanvasProps"
                @close="offcanvas.close"
            />
        </Teleport>
    </ClientOnly>
</template>
